const prefersDarkScheme = window.matchMedia(
	"(prefers-color-scheme: dark)"
).matches;
const themeToggleButton = document.querySelector("#theme-toggle");
const darkSchemePreference = prefersDarkScheme ? "dark" : "light";
const currentTheme = localStorage.getItem("theme")
	? localStorage.getItem("theme")
	: darkSchemePreference;
const rootElement = document.querySelector(":root");

if (currentTheme === "dark") {
	rootElement.classList.toggle("dark");
} else if (currentTheme === "light") {
	rootElement.classList.toggle("light");
}

const setToggleText = (theme) => {
	if (theme === "dark") {
		themeToggleButton.innerHTML = "Switch to light theme";
	} else {
		themeToggleButton.innerHTML = "Switch to dark theme";
	}
};

setToggleText(currentTheme);

// regular click
themeToggleButton.addEventListener("click", handleThemeToggle);

// specifically for accessibility, check if correct key has been pressed and call function
themeToggleButton.addEventListener("onkeydown", function (e) {
	if (e.key === " " || e.key === "Enter" || e.key === "Spacebar") {
		// "Spacebar" for IE11 support
		// Prevent the default action to stop scrolling when space is pressed
		e.preventDefault();
		handleThemeToggle();
	}
});

function handleThemeToggle() {
	rootElement.classList.toggle("light");
	rootElement.classList.toggle("dark");

	const theme = rootElement.classList.contains("light") ? "light" : "dark";
	localStorage.setItem("theme", theme);
	setToggleText(theme);
}
