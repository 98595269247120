import * as Sentry from "@sentry/browser";

const isProd = location.hostname === "emdr-lightbar.org";
const enabled = isProd || Boolean(localStorage.getItem("debug:sentry-enabled"));

Sentry.init({
	dsn: "https://3e1913c48a294fecbf31cc2470e45f65@o1386886.ingest.sentry.io/6707599",
	enabled,
	environment: isProd ? "production" : "development",
});

import "./lib/toggle-theme";
